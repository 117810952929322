import moment from "moment";

export const checkExpiration = (date) => {
  const currentDate = new Date();
  const expiryDate = new Date(date);
  if (currentDate > expiryDate) {
    return true;
  }
  return false;
};

export const CurrencyConverter = (string) => {
  if (string === "USD") {
    return "$";
  } else if (string === "AUD") {
    return "$";
  } else if (string === "INR") {
    return "₹";
  } else {
    return "$";
  }
};

export const InrToUsdConverter = (string, amount) => {
  if (string === "USD") {
    return AmountFormater(amount / 10);
  } else if (string === "AUD") {
    return AmountFormater(amount / 10);
  } else if (string === "INR") {
    return AmountFormater(amount * 10);
  } else {
    return AmountFormater(amount / 10);
  }
};

export const AmountFormater = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return number.toFixed(2);
  } else {
    return false;
  }
};

export const AllAlphbet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const detectBrowser = () => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf("Edg") > -1) {
    return "Edge";
  } else if (userAgent.indexOf("Chrome") > -1) {
    return "Chrome";
  } else if (userAgent.indexOf("Firefox") > -1) {
    return "Firefox";
  } else if (userAgent.indexOf("Safari") > -1) {
    return "Safari";
  } else if (
    userAgent.indexOf("MSIE") > -1 ||
    userAgent.indexOf("Trident") > -1
  ) {
    return "Internet Explorer";
  } else {
    return "Unknown";
  }
};

export const deviceDetection = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  if (width <= 600 && height >= width) {
    return "Mobile"; // Mobile in portrait
  } else if (width <= 800 && height < width) {
    return "Mobile"; // Mobile in landscape
  } else if (width <= 900) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};

export const agentAllowUrls = (location) => {
  const adminUrl = "/admin/";
  const allowUrls = [
    "dashboard",
    "profile",
    "client/sub-users",
    "exercise",
    "edit-exercise",
    "logout",
  ];
  for (let i = 0; i < allowUrls.length; i++) {
    if (location.startsWith(adminUrl + allowUrls[i])) {
      return true;
    }
  }
  return false;
};

export const subscriptionRemainingDays = (futureDate) => {
  let diffInDays = 0;
  if (futureDate) {
    const currentDate = moment();
    diffInDays = moment(futureDate).diff(currentDate, "days");
  }

  return diffInDays;
};
