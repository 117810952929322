// import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/************************ ADMIN API *****************************/

/************************ USER API *****************************/

export const GetStripeKey = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/common/payment-intent", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetPaypalKey = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/common/payment-intent", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetRazorPayKey = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/common/payment-intent", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const AddPaymentGetwayRes = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/common/add-payment-getway-log", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const AddCredit = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/user/add-credit", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const AddTransferCredit = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/user/transfer-credit", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetCreditPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await UserApi.get(`/user/credit-history?per_page=${limit}&page=${page}`)
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const AddSubscription = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/subscription/buy", data));
    } catch (error) {
      reject(error);
    }
  });
};
