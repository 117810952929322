import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Refund Policy | PhrasePulse</title>
        <meta
          name="description"
          content="Refunds may be granted under the following circumstances: You have been billed in error. Our services did not meet the specifications outlined in our agreement or as advertised on our website."
        />
        <link rel="canonical" href="https://phrasepulse.com/refund-policy" />
      </Helmet>
      <div className="inner-page">
        <Container>
          <Row>
            <Col>
              <div className="common-page mt-md-5 mt-lg-5">
                <h1>Refund Policy</h1>
              </div>
              <div>
                <p>
                  We at Phrasepulse.com strive to ensure that you are completely
                  satisfied with our services. However, we understand that there
                  may be circumstances where you may need to request a refund.
                  Please review our refund policy below:
                </p>
                <br />
                <p>
                  <b>1. Refund Eligibility:</b>
                </p>
                <div style={{ marginLeft: "20px" }}>
                  <p>
                    Refunds may be granted under the following circumstances:
                  </p>
                  <div style={{ marginLeft: "20px" }}>
                    <p>You have been billed in error.</p>
                    <p>
                      Our services did not meet the specifications outlined in
                      our agreement or as advertised on our website.
                    </p>
                  </div>
                </div>
                <br />
                <div>
                  <p>
                    <b>
                      2. Refunds will not be granted in the following
                      situations:
                    </b>
                  </p>
                  <div style={{ marginLeft: "20px" }}>
                    <p>
                      You have used our services beyond the [3-5] days period
                      after purchase.
                    </p>
                    <p>
                      You have violated our terms of service or engaged in
                      prohibited activities outlined in our terms of service
                      agreement.
                    </p>
                    <p>
                      The refund request is made after [3-5] days of purchase.
                    </p>
                  </div>
                </div>
                <br />
                <p>
                  <b>3. Refund Process:</b>
                </p>
                <div style={{ marginLeft: "20px" }}>
                  <p>
                    Once your refund request is approved, the refund will be
                    processed using the original method of payment.
                  </p>
                  <p>
                    Please note that it may take [3-5] business days for the
                    refunded amount to reflect in your account, depending on
                    your financial institution's processing time.
                  </p>
                </div>
                <br />
                <p>
                  <b>4. Cancellation of Membership:</b>
                </p>
                <div style={{ marginLeft: "20px" }}>
                  <p>
                    The membership will automatically expire after 30 days.
                    There’s no need to cancel it manually. If you want to
                    continue using the service after that period, you’ll need to
                    purchase a new subscription. You pay only when you decide to
                    subscribe again, and then you can enjoy the benefits for
                    another 30 days.
                  </p>
                </div>
                <br />
                <p>
                  <b>5. Contact Us:</b>
                </p>
                <div style={{ marginLeft: "20px" }}>
                  <p>
                    If you have any questions or concerns about our refund
                    policy, please contact us at phrasepulse[at]gmail[dot]com .
                    Our customer support team will be happy to assist you.
                  </p>
                  <p>
                    If you have any questions or concerns about our refund
                    policy, please contact us at phrasepulse[at]gmail[dot]com.
                    Our customer support team will be happy to assist you.
                  </p>
                </div>
                <br />
                <br />
                <div>Last updated: 1st March 2024</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
