import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge } from "@mui/material";
import NavDropdown from "react-bootstrap/NavDropdown";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import { GetNotificationPaginate } from "../../../Services/Api/notification";
import { Loader } from "../../../Utils/Loader";

const UserHeader = (props) => {
  const navigate = useNavigate();
  const { userDetails, handleDesktopToggel, sidebarDesktopToggel } = props;
  const userData = userDetails;
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const getNotificationPaginate = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetNotificationPaginate({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setNotifications(res?.data?.data?.data);
        } else {
          setNotifications([]);
        }
      })
      .catch((err) => {
        setNotifications([]);
        setIsLoading(false);
      });
  };

  const timeAgo = (time) => {
    return moment(time).fromNow();
  };

  useEffect(() => {
    getNotificationPaginate(2, 0);
  }, []);

  return (
    <div className="d-flex align-items-center w-100">
      <Link
        to="#"
        id="toggle_btn"
        onClick={() => handleDesktopToggel(sidebarDesktopToggel)}
      >
        {sidebarDesktopToggel ? <CloseOutlinedIcon /> : <MenuOutlinedIcon />}
      </Link>
      <Link
        to="#"
        className="mobile_btn"
        id="mobile_btn"
        onClick={() => props.onClick(!props.showSideBar)}
      >
        <MenuOutlinedIcon />
      </Link>
      <ul className="nav nav-tabs user-menu">
        <li>
          <Button
            variant="outline-primary"
            className="ppc-btn"
            onClick={() => navigate("/user/credit")}
          >
            <span className="ppc">PPC</span>{" "}
            <span className="amount">{userData?.user?.credit || 0}</span>
          </Button>
        </li>
        <li className="nav-item notification">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <Badge color="primary" badgeContent={2} showZero>
                <NotificationsNoneIcon />
              </Badge>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {!isLoading ? (
                notifications &&
                notifications?.map((notification, index) => (
                  <div className="notification-list" key={index}>
                    <h5 className="title">{notification?.title}</h5>
                    <p className="description">{notification?.message}</p>
                    <span className="time">
                      {timeAgo(new Date(notification?.createdOn))}
                    </span>
                  </div>
                ))
              ) : (
                <Loader loading={isLoading} />
              )}
              <div className="notification-list text-end">
                <Link to={`/user/notifications`}>All Notifications</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <Link className="nav-link"></Link>
        </li>

        <NavDropdown
          className="user-dropdown"
          id="nav-dropdown-dark-example"
          title={
            <span className="user-img">
              <img
                src={
                  userData?.user?.profile_picture ||
                  "/inner-assets/img/default_image.svg"
                }
                alt="imge"
              />
              <span className="status online"></span>
              <span className="username">{userData?.user?.name}</span>
            </span>
          }
        >
          <Link to="/user/profile" className="dropdown-item">
            <span className="icon">
              <PersonOutlineOutlinedIcon />
            </span>
            <span>Profile</span>
          </Link>

          <Link to="/user/credit" className="dropdown-item">
            <span className="icon">
              <PaymentsOutlinedIcon />
            </span>
            <span>Credit</span>
          </Link>

          <Link to="/user/plans" className="dropdown-item">
            <span className="icon">
              <ChangeCircleIcon />
            </span>
            <span>Membership</span>
          </Link>

          <Link to="/user/logout" className="dropdown-item">
            <span className="icon">
              <LogoutOutlinedIcon />
            </span>
            <span>Logout</span>
          </Link>
        </NavDropdown>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    userDetails: state?.reducers?.userDetails,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(UserHeader);
